<template>
    <div style="height: 95%" >
        <ejs-chart :theme="theme" align="center" id="chartcontainer" :title="title"
            :primaryXAxis="primaryXAxis" :primaryYAxis="primaryYAxis" :chartArea="chartArea" :width="width"
             :tooltip="tooltip">
            <e-series-collection>
                <e-series :dataSource="chartData" type="Pareto" xName="motivo_de_parada" yName="total_horas"
                    name="QUANTIDADE DE HORAS" width="2" columnWidth="0.4" :paretoOptions="paretoOptions"
                    :cornerRadius="cornerRadius" :fill="'#121935'">
                </e-series>
            </e-series-collection>
        </ejs-chart>
    </div>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import { Browser } from "@syncfusion/ej2-base";
import {
    ChartComponent,
    SeriesDirective,
    SeriesCollectionDirective,
    LineSeries,
    StackingColumnSeries,
    Tooltip,
    ColumnSeries,
    Category,
    Legend,
    ParetoSeries,
    Highlight,
} from "@syncfusion/ej2-vue-charts";

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Fluent2";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
    .replace(/-dark/i, "Dark")
    .replace(/contrast/i, "Contrast")
    .replace(/-highContrast/i, "HighContrast");

export default {
    name: "pareto_motivo_de_parada",
    components: {
        "ejs-chart": ChartComponent,
        "e-series-collection": SeriesCollectionDirective,
        "e-series": SeriesDirective,
    },

    props: {
        dataPareto: Object
    },
    data: () => {
        return {
            dialog: false,
            theme: theme, 
            primaryXAxis: {
                interval: 20,
                valueType: "Category",
                majorGridLines: { width: 0 },
                minorGridLines: { width: 0 },
                majorTickLines: { width: 0 },
                minorTickLines: { width: 0 },
                lineStyle: { width: 0 },
                labelIntersectAction: Browser.isDevice ? "None" : "Rotate45",
                labelRotation: Browser.isDevice ? -45 : 0,
            },
            primaryYAxis: {
                title: "Soma do Tempo do Apontamento",
                minimum: 0.0,
                interval:20,
                lineStyle: { width: 0 },
                majorTickLines: { width: 0 },
                majorGridLines: { width: 1 },
                minorGridLines: { width: 1 },
                minorTickLines: { width: 0 },
            },
            chartArea: {
                border: {
                    width: 0,
                },
            },
            paretoOptions: {
                marker: { visible: true, isFilled: true, width: 7, height: 7 },
                dashArray: "3,2",
                width: 2,
            },
            cornerRadius: {
                topLeft: Browser.isDevice ? 4 : 6,
                topRight: Browser.isDevice ? 4 : 6,
            },
            width: Browser.isDevice ? "100%" : "75%",
            legendSettings: {
                visible: true,
                enableHighlight: true,
            },
            tooltip: {
                enable: true,
                shared: true,
                format: "${series.name} : <b>${point.y}</b>",
            },
            title: "Motivo de Parada / Máquina:",
        };
    },
    provide: {
        chart: [
            StackingColumnSeries,
            LineSeries,
            Category,
            ColumnSeries,
            Legend,
            Tooltip,
            ParetoSeries,
            Highlight,
        ],
    },

    computed: {
        appCharts() {
            return useAppCharts();
        },
        formattedName() {
            return this.formatTeste();
        },
        chartData() {
            return this.dataPareto
        },
    },
};
</script>