import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"height":"100%"}
}
const _hoisted_2 = {
  key: 1,
  height: "100%",
  class: "mt-2"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_toolbarCharts = _resolveComponent("toolbarCharts")
  const _component_Pareto_motivo_de_parada = _resolveComponent("Pareto_motivo_de_parada")
  const _component_sem_infos = _resolveComponent("sem_infos")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_container = _resolveComponent("v-container")

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { height: "calc(100vh - 55px)" }, {
        default: _withCtx(() => [
          _createVNode(_component_toolbarCharts, {
            onToolbarFiltros: $options.toolbarFiltros,
            onOnGetGraficInformation: $options.onGetGraficInformation
          }, null, 8 /* PROPS */, ["onToolbarFiltros", "onOnGetGraficInformation"]),
          ($options.appCharts.show_motivo_de_parada)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_Pareto_motivo_de_parada, { dataPareto: $data.dataPareto }, null, 8 /* PROPS */, ["dataPareto"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_sem_infos)
              ]))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}