<template>
    <v-container fluid>
        <v-card height="calc(100vh - 55px)">
            <toolbarCharts @toolbarFiltros="toolbarFiltros" @onGetGraficInformation="onGetGraficInformation" />
            <div v-if="appCharts.show_motivo_de_parada" style="height: 100%">
                <Pareto_motivo_de_parada :dataPareto="dataPareto"/>
            </div>
            <div v-else height="100%" class="mt-2">
                <sem_infos />
            </div>
        </v-card>
    </v-container>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import Pareto_motivo_de_parada from "./pareto_motivo_de_parada.vue";
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-vue-calendars/styles/material.css';
import sem_infos from '../modals/semInfos.vue'
import toolbarCharts from '../charts/toolbar/index.vue'
import { useRouter, useRoute } from "vue-router";

export default {
    name: "motivo_de_parada",
    components: {
        Pareto_motivo_de_parada,
        toolbarCharts,
        sem_infos
    },

    computed: {
        appCharts() {
            return useAppCharts();
        }
    },
    data() {
        return{
            shifts: [],
            machines: [],
            startDate: [],
            endDate: [],
            Router : useRouter(),
            dataPareto: null,
            current_path : '/app/apontamento-iot/dash/paretoParadas/'
        }
    },
    mounted() {
        this.dataPareto = null
        this.getDataPareto()
    },
    methods: {
        toolbarFiltros(pFiltro, pValue) {
            switch(pFiltro) {
                case 'shift':
                    this.shifts = pValue
                    break;
                case 'machine':
                    this.machines = pValue
                    break;
                case 'startDate':
                    this.startDate = pValue
                    break
                case 'endDate':
                    this.endDate = pValue
                    break
                }
        },
        onGetGraficInformation(event) {
            this.datapareto = null
            this.getDataPareto()
        },
        getDataPareto() {
            this.appCharts.relatorio_de_motivos_de_parada(this.shifts, this.startDate, this.endDate, this.machines).then(() => {
                this.$nextTick(() => {
                   this.dataPareto = this.appCharts.resourceParetoDeParadas
                })
            })
        }
    }
}
</script>