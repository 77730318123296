<template>
    <v-container fluid>
        <v-card :height="display == 'lg' || display == 'xl' || display == 'xxl' ? 'calc(100vh - 55px)' : '' ">
            <toolbarCharts @toolbarFiltros="toolbarFiltros" @onGetGraficInformation="onGetGraficInformation"/>
            <div v-if="appCharts.show_disponibilidade_resumida" style="height: 60%">
                <pie_disponibilidede_resumida  :dataChart="data_grafico_e_grid"/>
            </div>
            <div v-if="appCharts.show_disponibilidade_resumida" style="height: 35%">
                <tabela_disponibilidade_resumida :gridDetalhe="data_grafico_e_grid" :gridGeral="data_grid_geral" :gridMaq="data_grid_maq"/>
            </div>
            <div v-else height="100%" class="mt-2">
                <sem_infos />
            </div>
        </v-card>
    </v-container>
</template>

<script>
import tabela_disponibilidade_resumida from "../charts/tabela_disponibilidade_resumida.vue";
import pie_disponibilidede_resumida from "./pie_disponibilidede_resumida.vue";
import toolbarCharts from '../charts/toolbar/index.vue'
import sem_infos from '../modals/semInfos.vue'
import { useAppCharts } from "../../../stores/appCharts";
import { decimalToTime }  from "../../utils/funcoes";
import { useDisplay } from 'vuetify'
import { useResponsiveSizes } from '../../utils/style'


export default {
    name: "disponibilidade_resumida",
    components: {
        tabela_disponibilidade_resumida,
        toolbarCharts,
        pie_disponibilidede_resumida,
        sem_infos
    },
    computed: {
        appCharts() {
            return useAppCharts()
        },
        gritHeightPlus() {
            let soma = this.gridHeight + 115
            return soma + 'px'
        }
    },
    data() {
        return {
            data_grafico_e_grid: null,
            data_grid_maq: null,
            data_grid_geral: null,
            selectedStartDate: [],
            selectedEndDate: [],
            selectedMachine: [],
            selectedShift: [],
            display: useDisplay().name,
            gridHeight: useResponsiveSizes().gridHeight
        }
    },
    mounted() {
        this.getDataDisp()
    },
    methods: {
        toolbarFiltros(pFiltro, pValue) {
            switch (pFiltro) {
                case 'machine':
                    this.selectedMachine = pValue
                    break;
                case 'shift':
                    this.selectedShift = pValue
                    break;
                case 'startDate':
                    this.selectedStartDate = pValue
                    break
                case 'endDate':
                    this.selectedEndDate = pValue
                    break
            };
        },
        onGetGraficInformation() {
            this.data_grafico_e_grid = null,
            this.data_grid_maq = null,
            this.data_grid_geral = null,
            this.getDataDisp()
        },
        getDataDisp() {
            
            this.appCharts.disp_resumida(this.selectedMachine, this.selectedShift, this.selectedStartDate, this.selectedEndDate).then(() => {                
                this.$nextTick(() => {
                    this.data_grafico_e_grid = this.appCharts.resourceDataDisp.grafico_grid.map(Apont => ({
                        nameApont: Apont.tipo == 'Produção' ? Apont.tipo : Apont.desc_motivo_parada,
                        total_hr: Apont.total_hr,
                        total_hr_formated: decimalToTime(Apont.total_hr)
                    })),
                    console.log('como q ta: ', this.appCharts.resourceDataDisp)
                    this.data_grid_geral = this.appCharts.resourceDataDisp.grid_disp_geral.map(hora => ({
                        hrs_totais: decimalToTime(hora.hrs_totais),
                        hrs_totais_prod: decimalToTime(hora.hrs_totais_prod),
                        indice_disp: hora.indice_disp + '%'
                    })),
                    this.data_grid_maq = this.appCharts.resourceDataDisp.grid_disp_maq.map(dados => ({
                        hrs_disp: decimalToTime(dados.total_hr_turnos_disponivel),
                        hrs_prod: decimalToTime(dados.total_hr_producao),
                        indice_disp: dados.percentual_producao.toFixed(2) + '%',
                        turno: dados.turno,
                        data: dados.data
                    }))
                })
            })
        }
    }
};
</script>