<template>
    <div style="background-color: #dce0e6; height: 100% !important;">

<v-row class="ma-2">
    <v-col cols="12" md="5" class="pa-1">
        <div style="height: 100%;">
            <small style="font-size: 16px; font-weight: bold;"> Tempo de Disponibilidade das máquinas por Data e
                Turno</small>
            <div style="height: calc(31vh - 30px)">
                <ejs-grid ref='grid' id='grid' height="100%" :dataSource="dataGridMaq" :allowFiltering='true' :allowSorting='true'>
                    <e-columns>
                        <e-column field='data' headerText='Data' width='150'></e-column>
                        <e-column field='turno' headerText='Turno' width='150'></e-column>
                        <e-column field='hrs_disp' headerText='Horas' width='150'></e-column>
                        <e-column field='hrs_prod' headerText='Horas Produtivas' width='150'></e-column>
                        <e-column field='indice_disp' headerText='Índice' width='100'></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </v-col>
    <v-col cols="12" md="3" class="pa-1">
        <div>
            <small style="font-size: 16px; font-weight: bold;">Detalhamento de Disponibilidade</small>
            <div style="height: calc(31vh - 30px);">
                <ejs-grid ref='grid' id='grid' height="100%" :dataSource="dataGridDetalhe" :allowFiltering='true'
                    :allowSorting='true'>
                    <e-columns>
                        <e-column field='nameApont' headerText='Status' width='150'></e-column>
                        <e-column field='total_hr_formated' headerText='Duração' width='150'></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </v-col>
    <v-col cols="12" md="4" class="pa-1">
        <div>
            <small style="font-size: 16px; font-weight: bold;">Disponibilidade Geral</small>
            <div style="height: calc(31vh - 30px);">
                <ejs-grid ref='grid' id='grid' height="100%" :dataSource="dataGridGeral" :allowFiltering='true'
                    :allowSorting='true'>
                    <e-columns>
                        <e-column field='hrs_totais' headerText='Hrs Disp.' width='150'></e-column>
                        <e-column field='hrs_totais_prod' headerText='Hrs Prod.' width='150'></e-column>
                        <e-column field='indice_disp' headerText='Índice de Disp.' width='150'></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </v-col>
</v-row>
</div>
  
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids'; 

export default {
    name: "tabela_disponibilidade_resumida",
    components: {
        'ejs-grid': GridComponent,
        'e-columns': ColumnsDirective,
        'e-column': ColumnDirective,
    },
    props: {
        gridDetalhe: Object,
        gridGeral: Object,
        gridMaq: Object
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
        dataGridDetalhe() {
            return this.gridDetalhe
        },
        dataGridGeral(){
            return this.gridGeral
        },
        dataGridMaq() {
            return this.gridMaq
        }

    }
}
</script>
