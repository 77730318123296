import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"height":"60%"}
}
const _hoisted_2 = {
  key: 1,
  style: {"height":"35%"}
}
const _hoisted_3 = {
  key: 2,
  height: "100%",
  class: "mt-2"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_toolbarCharts = _resolveComponent("toolbarCharts")
  const _component_pie_disponibilidede_resumida = _resolveComponent("pie_disponibilidede_resumida")
  const _component_tabela_disponibilidade_resumida = _resolveComponent("tabela_disponibilidade_resumida")
  const _component_sem_infos = _resolveComponent("sem_infos")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_container = _resolveComponent("v-container")

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        height: $data.display == 'lg' || $data.display == 'xl' || $data.display == 'xxl' ? 'calc(100vh - 55px)' : '' 
      }, {
        default: _withCtx(() => [
          _createVNode(_component_toolbarCharts, {
            onToolbarFiltros: $options.toolbarFiltros,
            onOnGetGraficInformation: $options.onGetGraficInformation
          }, null, 8 /* PROPS */, ["onToolbarFiltros", "onOnGetGraficInformation"]),
          ($options.appCharts.show_disponibilidade_resumida)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_pie_disponibilidede_resumida, { dataChart: $data.data_grafico_e_grid }, null, 8 /* PROPS */, ["dataChart"])
              ]))
            : _createCommentVNode("v-if", true),
          ($options.appCharts.show_disponibilidade_resumida)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_tabela_disponibilidade_resumida, {
                  gridDetalhe: $data.data_grafico_e_grid,
                  gridGeral: $data.data_grid_geral,
                  gridMaq: $data.data_grid_maq
                }, null, 8 /* PROPS */, ["gridDetalhe", "gridGeral", "gridMaq"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_sem_infos)
              ]))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["height"])
    ]),
    _: 1 /* STABLE */
  }))
}